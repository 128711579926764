<template>
<v-card flat>
    <v-card-title ref="chartTitle" class="mt-5" :class="`mt-5 ${getTitleSize()}`">
        <v-divider class="ma-5" />
            Choose Destination
        <v-divider class="ma-5" />
    </v-card-title>
    <v-card-text class="pb-0">
            <v-container>
                <v-row>
                    <v-col>
                        <v-card outlined>
                            <div id="chart" ref="chart" />
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
    </v-card-text>
</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Highcharts from "highcharts";
import loadMap from "highcharts/modules/map.js";
import mapData from "@highcharts/map-collection/custom/world.geo.json";

export default {
    name: "WorldMap",

    data() {
        return {
            chart: undefined,
            countryData: []
        }
    },

    computed: {
        ...mapGetters(["countries"])
    },

    async mounted() {
        this.countries.forEach((el) => this.countryData.push([el.id.toLowerCase(), 1]));

        let primaryColor = this.$vuetify.theme.themes.light.primary;
        let secondaryColor = this.$vuetify.theme.themes.light.secondary;

        loadMap(Highcharts);
        Highcharts.maps['worldMap'] = mapData

        this.chart = Highcharts.mapChart('chart', {
            chart: {
                map: 'worldMap',
                height: this.$vuetify.breakpoint.xsOnly ? "100%" : "50%"
            },
            title: { text: '' },
            subtitle: { text: 'Select a country to view all related posts', style: {'font-family': '"Raleway", sans-serif !important;'} },
            legend: { enabled: false },
            mapNavigation: { 
                enabled: true, 
                enableDoubleClickZoom: true,
                enableTouchZoom: true,
                enableMouseWheelZoom: true
            },

            series: [{
                name: 'Country',
                data: this.countryData,
                color: secondaryColor,
                //allowPointSelect: true,
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF',
                    formatter: function () {
                        if (this.point.value) {
                            return this.point.name;
                        }
                    },
                    style: {'font-family': '"Raleway", sans-serif !important;'}
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.name}'
                },
                states: {
                    hover: {
                        color: primaryColor
                    },
                    select: {
                        color: primaryColor,
                        borderColor: 'black'
                    }
                },
            }],
            plotOptions:{
                series:{
                    point:{
                        events:{
                            click: async (ev) => {
                                var searchTerm = ev.point['hc-key'].toUpperCase();
                                var countryName = ev.point['name'];
                                this.$parent.$emit('search', true, { type: 'country', term: searchTerm, name: countryName });
                            },
                            mouseOver: () => {
                                this.$refs.chart.classList.add("cursor");
                            },
                            mouseOut: () => {
                                this.$refs.chart.classList.remove("cursor");
                            }
                        }
                    }
                }
            }
        });
    },

    methods: { },

    watch: {
        '$vuetify.breakpoint.name'(val) {
            var height = (val === "xs") ? "100%" : "50%";
            this.chart.setSize(undefined, height, false);
            this.chart.redraw();
        }
    }
};
</script>

<style lang="scss">
.cursor {
    cursor: pointer;
}
</style>